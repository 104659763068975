@import './variables.scss';
@import './custom.scss';

:root, html {
  scroll-behavior: initial;
}

[data-env="uat"], [data-env="development"] {
  [data-env-impose="true"] {
    position: relative;
    width: 100%;
  
    &::before {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) rotate(-30deg);
      transform: translate(-50%, -50%) rotate(-15deg);
      color: #d94e35;
      font-size: 60px;
      border-top: 5px solid #d94e35;
      border-bottom: 5px solid #d94e35;
      font-weight: 900;
      text-transform: uppercase;
      text-shadow: 0px 5px #c5c5db;
    }
    &.brand-logo::before {
      font-size: 30px;
      transform: translate(-50%, -50%) rotate(-10deg);
    }
  }

  &[data-env="development"] {
    [data-env-impose="true"] {
      &::before {
        content: "DEV";
      }
    }
  }

  &[data-env="uat"] {
    [data-env-impose="true"] {
      &::before {
        content: "TEST";
      }
    }
  }
  
}

.kt-splash-screen {
  background-color: $primary;
}

.text-danger-hover {
  &:hover, &:focus {
    &, i {
      color: $danger !important;
    }
  }
}

.text-success-hover {
  &:hover, &:focus {
    &, i {
      color: $success !important;
    }
  }
}

.text-info-hover {
  &:hover, &:focus {
    &, i {
      color: $info !important;
    }
  }
}

.text-warning-hover {
  &:hover, &:focus {
    &, i {
      color: $warning !important;
    }
  }
}

html, body {
  height: auto;
}

.no-event {
  pointer-events: none;
  user-select: none;
}

.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}

.card-screen {
  .card-header {
    display: flex;
    justify-content: space-between;
    padding: 1.35rem 2.25rem;

    @media (max-width: 576px) {
        flex-wrap: wrap;
        justify-content: flex-start;

        .card-screen-title {
            margin-bottom: 15px;
        }

        .card-screen-action {
            display: flex;
            width: 100%;
            flex-direction: column;
            .btn {
                width: 100%;
                margin-left: 0 !important;
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .card-screen-title {
        display: flex;
        align-items: center;
        color: #181C32; 
        font-size: 2em !important;

        i, img {
            width: 24px;
            margin-right: 15px;
            font-size: 24px;
            color: #181C32; 
        }

        h1 {
            margin-bottom: 0;
        }
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
}

.sticky-message {
  padding: 0 56px 25px 0 !important;
}

.text-ellipsis {
  display: inline-block;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

#root {
  height: auto;
  min-height: 100vh;
}

.brand-logo {
  justify-content: flex-start;
  height: 80%;
  width: 100%;
  img {
    // width: 100%;
  }
}

.header-mobile img {
  height: 40px;

  @include media-min-lg {
    height: 60px;
  }
}

.header-mobile-fixed .header-mobile {
  z-index: 1000;
}

.form-higher {
  position: relative;
  z-index: 2;
}

.align-checkbox {
  margin-top: 57px;
}

#kt_quick_user {
  .tab-content {
    padding-top: 15px;
  }
}

.form-higher-1 {
  position: relative;
  z-index: 3;
}

.form-higher-2 {
  position: relative;
  z-index: 4;
}

.form-higher-3 {
  position: relative;
  z-index: 5;
}

.form-higher-4 {
  position: relative;
  z-index: 6;
}

.form-higher-5 {
  position: relative;
  z-index: 7;
}

.form-highest {
  position: relative;
  z-index: 3;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.no-action {
    border: 1px solid #eaedf2;

    td:not([data-facade]) {
        padding: 0.95rem 0.75rem !important;
    }
}
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.pagination {
  .page-item .page-link {
    z-index: 0 !important;
  }
}

body.modal-open {
  #root {
    overflow: hidden;
  }
}

.modal ~ .modal {
  z-index: 1062;
}

.modal-backdrop ~ .modal-backdrop {
  z-index: 1061;
}

.modal.highlayer {
  z-index: 1052;
}
.modal > .modal-dialog {
  @include media-max-sm {
    margin-top: 60px;
  }
}

.modal-dialog.modal-xxl {
  max-width: 1000px;
}

@media (min-width: 1200px) {
  .modal-dialog.modal-xxl {
      max-width: 1140px;
  }
}

@media (min-width: 1500px) {
  .modal-dialog.modal-xxl {
      max-width: 1440px;
  }
}

@media (min-width: 1800px) {
  .modal-dialog.modal-xxl {
      max-width: 1740px;
  }
}

@media (min-width: 2100px) {
  .modal-dialog.modal-xxl {
      max-width: 2040px;
  }
}

.modal-normal > .modal-content {
  margin-left: 0 !important;
}

.custom-control:not(.custom-switch) {
  .custom-control-label::before, 
  .custom-control-label::after {
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .custom-control-label {
    padding-left: 1rem;
  }
}

.show-only-file {
  .file-group {
     border: 0;
  }
}

.flex-1 {
  flex: 1 1;
}

.modal .modal-header .close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em;
  color: #000;
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5;

  [aria-hidden="true"] {
    display: none;
  }
}

.modal {
  .tab-content > .tab-pane {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .modal:not(.user-profile):not(.modal-carousel):not(.modal-preview):not(.modal-fullscreen):not(.right) .modal-dialog:not(.modal-xl):not(.modal-xxl) .modal-content {
    margin-left: 27%;
  }

  .modal-sm > .modal-content {
    margin-left: 47% !important;
  }
}

@media (min-width: 1100px) {

  .modal-lg > .modal-content {
    margin-left: 17% !important;
  }
  
}
@media (min-width: 1420px) {

  .modal-xl > .modal-content {
    margin-left: 12%;
  }
  
}

.notification-item {
  position: relative;
  cursor: pointer;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 15px;
  .facade {
    width: 100%;
    .facade-title {
      width: 38%;
      height: 14px;
      margin-top: 0px;
      margin-bottom: 0px;
      border-radius: 5px;
    }

    .facade-description > ul {
      padding: 0;
      margin-top: 5px;
      margin-bottom: 5px;

      > li {
        list-style: none;
        height: 12px;
        border-radius: 5px;

        + li {
          margin-top: 7px;
        }
      }
    }
  }
  &:not(.read) {
      background-color: #f5f5f5;

      .notification-title {
          div::after {
              content: '';
              display: inline-block;
              margin-left: 5px;
              width: 10px;
              height: 10px;
              border-radius: 50px;
              background-color: sandybrown;
          }
      }
  }

  .notification-actions {
      display: none;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
  }

  &:hover {
      .notification-actions {
          display: flex;
      }
  }
}

.notification-actions-dropdown {
  &.active .dropdown-toggle {
      background-color: #f2f2f2;
  }
  .dropdown-toggle {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      background-color: #ffffff;
      border-radius: 50%;
      font-size: 17px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.231);

      &:hover {
          background-color: #f2f2f2;
      }

      &::after {
          display: none;
      }
  }
}

.notification-no-record {
  padding: 1rem 0;
  text-align: center;

  .fa {
      font-size: 40px;
  }

  p {
      margin-top: 1rem;
      font-size: 17px;
  }
}

.notification-badge {
  position: absolute;
  right: 0;
  border-radius: 50px;
  padding: 5px 7px;
}

.tight-form {
  .form-group {
    margin-bottom: 1rem !important;
  }
}

.react-datepicker .react-datepicker__year-option .react-datepicker__navigation {
  top: 0px;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  color: #fff;
}

iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
  display: none;
}

td {
  .form-number {
    height: 100%;
  }
}

#kt_quick_user {
  .offcanvas-content {
    .d-flex > .flex-column {
      max-width: 66.66667%;
    }

    .navi {
      .navi-text {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.spinner-border {
  vertical-align: middle;
}

body {

  .bg-gray {
      background-color: #eee;
  }

  .login-form .max-h-70px {
      max-height: auto !important;
  }

  .text-not-muted {
      .text-muted {
          color: #5E6278 !important;
      }
  }

  .grid-field {
      position: relative;
      overflow-y: hidden;
      overflow-x: hidden;
  }

  .form-control[type="color"] {
    height: 38px;
    padding: 3px;
  }

  .btn-input-add {
    margin-top: 5px;
    padding: 2px 10px !important;
    font-size: 12px !important;
  }

  &.modal-open {
    overflow: hidden !important;
    // #root {
    //   overflow: hidden;
    // }

    // &, #kt_header {
    //   // padding-right: 15px !important;
    // }
  }

  .card {
      .card-header {
          border-bottom: 0;
      }
  }

  .input-group { 
    .input-group-text {
      padding: 0.5rem 1rem;
    }
  }

  .select-dropdown:not(.multiple) > input:focus,
  .form-number > input:focus,
  .auto-complete-select .css-1pahdxg-control,
  .auto-complete-select .css-1pahdxg-control:hover {
    border-color: $primary;
  }

  .select-dropdown > .select-toggle {
    min-height: calc(1.5em + 1.3rem + 2px);
  }

  .auto-complete-select {
      .css-yk16xz-control {
        min-height: calc(1.5em + 1.3rem + 2px);
      //     padding: 0rem 0.45rem !important;
      //     // border-color: #707070 !important;
      //     border-radius: 0 !important;
      //     min-height: 42px;
      //     // border-color:  #6c757d;
      }

      // &.is-invalid > .css-yk16xz-control {
      //     border-color: #dd3444 !important;
      // }

      // .css-1fhf3k1-control {
      //     padding: 0rem 0.45rem !important;
      //     border-radius: 0 !important;
      //     border-color: #6c757d;
      //     min-height: 42px;
      //     box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.2)
      // }

      // .css-1pahdxg-control {
      //     padding: 0rem 0.45rem !important;
      //     border-radius: 0 !important;
      //     border-color: #6c757d;
      //     min-height: 42px;
      //     box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.2);

      //     &:hover {
      //         border-color: #707070;
      //         box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.2)
      //     }
      // }

      .css-1wa3eu0-placeholder {
          color: #b6b6c4;
      }

      .css-26l3qy-menu {
          top: 103%;
          margin: 0;
          border-radius: 1px;
          padding: 5px 0px;

          .css-4ljt47-MenuList {
              padding: 0;

              .css-1n7v3ny-option {
                  padding: 9px 10px;
                  // background-color: rgba(230, 39, 43, 0.078) !important;
                  // color: #af5254;
              }
              .css-yt9ioa-option {
                  padding: 9px 10px;
              }
          }
      }
  }
  .custom-checkbox .custom-control-label::before {
      background-color: #EBEDF3;
      border: 1px solid transparent;
  }

  .form-number-spinner-add, .form-number-spinner-minus {
      background-color: #ecf1f5;
      border-radius: 5px;
  }

  .disconnected-job-modal .modal-body .text-content {
      white-space: pre-line;
  }

  .accordion .card .card-header {
      padding: 10px 15px;
      background-color: #fff;
  }

  .grid-no-action {
      border: 1px solid #eaedf2;
  
      > tbody > tr > td:not([data-facade]) {
          padding: 0.95rem 0.75rem !important;
      }
  }

  .collapsible-container {
      .grid-table .grid-content {
          margin-left: 49px;
          min-height: 0;
          margin-bottom: 0;

          .table {
              margin-bottom: 0;
          }
      }
  }

  .grid-table {
    .grid-content {
      .table.table-head-bg thead tr, .table.table-head-bg thead th {
          background-color: #F3F6F9;
          color: #7e8299 !important;
      }

      table td {
        height: 46px;
        font-size: 13px;
      }
    } 

    .grid-footer {
      .grid-pageinfo .dropdown .dropdown-toggle {
  
        &:focus, &:hover {
            background-color: $primary !important; 
        }
      }
      .pagination .page-item {
        &.active .page-link, .page-link:hover {
          background-color: $primary;
        }
      }
    }
  }
  
  .mono-text {
      font-family: monospace;
  }

  .tab-content {
      background-color: #fff;
      padding-top: 20px;
  }

  .editor-height-up {
      .rdw-editor-main {
          height: 350px !important;
      }
  }
  
  .select-draggable .draggable-list [data-facade] .facade-description > ul {
      padding: 0;
      margin-top: 10px;
      margin-bottom: 10px;
  }

  .custom-switch.custom-switch-xl .custom-control-label {
      padding-left: 2.9rem !important;
      padding-bottom: 1rem !important;
      padding-top: 0.6rem !important;
  }
  
  .input-group-prepend {
      .select-dropdown {
          z-index: 1;
          width: 100px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
  
          .select-toggle {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
          }
      }
  }

  .spread-sheet {
      .input-group {
          height: 100%;
          .input-group-prepend {
              border-radius: 0;
              border: 0;
              .input-group-text {
                  border: 0;
                  border-radius: 0;
              }
          }
      }
  }
  
  .input-group {
      .form-number {
          position: relative;
          flex: 1 1 auto;
          width: 1%;
          min-width: 0;
          margin-bottom: 0;
  
          input {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
          }
      }

      i {
          line-height: 1;
      }
  }

  fieldset {
      margin-inline-start: 2px;
      margin-inline-end: 2px;
      padding: 15px;
      border: 1px solid #e3e6ef;
  
      legend {
          width: auto;
      }
  }

  .form-group {
      fieldset {
      
          legend {
              margin-left: auto;
              width: 24px;
              text-align: center;
              cursor: pointer;
          }
      }
  }

  .pagination {
      align-items: center;
      margin-bottom: 0;

      .page-item .page-link {
          padding: 0.4rem 0.6rem;
          background-color: transparent;
          border-color: transparent;
      }

      .page-item.disabled .page-link {
          color: #d2d3de;
      }
      
      .page-item.active .page-link, .page-item .page-link:hover {
          color: #000;
          background-color: transparent;
          border-color: transparent;
      }

      .page-item .page-link > span[aria-hidden] {
          line-height: 0.9;
          font-size: 20px;
      }

  }

  .link {
      text-decoration: underline;
  }

  .negative-m-3 {
      margin-top: -3px;
  }

  .offcanvas.offcanvas-right.offcanvas-on {
    overflow: auto;
  }

  @include media-max-sm {
    .scrolltop {
      bottom: 60px;
    }

    .card {
      .card-body, .card-header {
        padding: 15px !important;
      }
    }
    
    .pagination {
      flex-wrap: wrap;
      li {
        margin-bottom: 15px;
      }
    }

    .spread-sheet {
      .table {
        tr {
          td, th {
            width: 100px;
            min-width: 100px;
          }
        }
      }
      .spread-sheet-footer {
        position: sticky;
        left: 0px;
      }
    }

    .grid-pageinfo {
      .dropdown-menu {
        width: auto !important;
      }
    }

    .draggable-list {
      .draggable-list-header {
        .draggable-list-title:not(:first-child) {
          display: none;
        }
        .draggable-list-indicator {
          display: block;

          & + .draggable-list-title {
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
            display: block;
          }
        }
      }
      .draggable-list-body {
        .draggable-list-row {
          .draggable-list-data:not(:first-child) {
            display: none;
          }
          .draggable-list-indicator {
            display: block;
  
            & + .draggable-list-data {
              display: block;
            }
          }
        }
      }
    }
  }
}

.icon-color-yellow {
  color: #feeb4f !important;
}