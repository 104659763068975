// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "@metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "@metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "@metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "@metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "@metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "@metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "@metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "@metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "@metronic/_assets/sass/themes/layout/header/menu/dark.scss";

@import "@metronic/_assets/plugins/keenthemes-icons/font/ki.css";
@import "@metronic/_assets/plugins/flaticon/flaticon.css";
@import "@metronic/_assets/plugins/flaticon2/flaticon.css";
@import "@metronic/_assets/sass/pages/login/classic/login-1.scss";
// @import "socicon/css/socicon.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";

// Brand themes
.brand-dark {
  @import "@metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "@metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "@metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "@metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.modal-backdrop ~ .modal-backdrop {
  z-index: 1051;
}

.modal.highlayer {
  z-index: 1052;
}

@media (min-width: 992px) {
  // .modal-content {
  //   margin-left: 27%;
  // }

  .modal-sm > .modal-content {
    margin-left: 47%;
  }
}

@media (min-width: 1100px) {

  .modal-lg > .modal-content {
    margin-left: 17%;
  }
  
}

#kt_content {
  position: relative;
}

.custom-control-input {
  z-index: 1;
}

.custom-switch {
  user-select: none;
  margin-bottom: 0;
}

.custom-switch .custom-control-label {
  cursor: pointer;
  padding-left: 3rem  !important;
  padding-top: 0.67rem !important;
}

.custom-switch .custom-control-label::before {
  height: 2.5rem  !important;
  width: calc(4rem + 0.75rem)  !important;
  border-radius: 5rem  !important;
}

.custom-switch .custom-control-label::after {
  width: calc(2.5rem - 4px)  !important;
  height: calc(2.5rem - 4px)  !important;
  border-radius: calc(4rem - (2.5rem / 2))  !important;

}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(2.5rem - 0.25rem)) !important;
}

// .nav-link:focus {  
//     outline: none;  
// }
.show-only-file {
  .file-preview-remove {
    display: none;
  }
}
.product-condition {
  position: relative;
  left: -10px;
  cursor: pointer;
}
body {
.react-datepicker .react-datepicker__header {
        background-color: $primary;
    }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    border-bottom-color:$primary !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    border-top-color:$primary !important;
}
.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected,
.react-datepicker .react-datepicker__day--selected, 
.react-datepicker .react-datepicker__day--in-selecting-range,
.react-datepicker .react-datepicker__day--in-range,
.react-datepicker .react-datepicker__month-text--selected,
.react-datepicker .react-datepicker__month-text--in-selecting-range,
.react-datepicker .react-datepicker__month-text--in-range,
.react-datepicker .react-datepicker__quarter-text--selected,
.react-datepicker .react-datepicker__quarter-text--in-selecting-range,
.react-datepicker .react-datepicker__quarter-text--in-range,
.react-datepicker .react-datepicker__year-text--selected,
.react-datepicker .react-datepicker__year-text--in-selecting-range,
.react-datepicker .react-datepicker__year-text--in-range,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color:$primary;
    color: #fff;
}
.react-datepicker__close-icon {
  &:hover::after {
      color: #999;
  }

  &::after {
      cursor: pointer;
      background-color: transparent;
      color: #ccc;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      padding: 10px 15px;
      font-size: 20px;
      font-weight: 600;
  }
}
}