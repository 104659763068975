.custom-public {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    min-height: 100vh;

    
    .text-common {
        color: #7a7a7a;
        font-family: "Source Serif Pro",Sans-serif;
        font-weight: 400;
    }

    .text-title {
        color: #7a7a7a;
        font-family: "Playfair Display",Sans-serif;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    .text-title-primary {
        color: #0393A7;
        font-family: "Playfair Display",Sans-serif;
        font-weight: 600;   
        padding: 0;
        margin: 0;
        line-height: 1; 
        font-size: 1.5rem;
    }
    
    .large-text {
        font-size: 1.3em;
    }

    .color-white {
        color: #fff;
    }

    @media only screen and (min-width: 767px) {
        .massive-text {
            font-size: 3.5em;
        }
    }

    .massive-text {
        font-family: "Playfair Display";
        font-weight: 600;
        line-height: 1em;
    }

    p {
        margin-top: 0;
        margin-bottom: .9rem;
    }

    &-header {
        position: sticky;
        top: 0;
        width: 100%;
        border-style: solid;
        border-width: 0 0 1px 0;
        border-color: #d6d6d6;
        background-color: #fff;
        transition: border 0.3s,border-radius 0.3s,box-shadow 0.3s;
        padding: 5px 020px 5px 20px;
        z-index: 100;
        display: flex;
    }

    &-navbrand, &-navbar, &-navaction {
        display: flex;
        align-items: center;
        padding: 10px;
    }

    &-navbrand {
        
        img {
            width: 200px;
        }
    }

    &-navbar {
        margin-left: auto;

        &-item {
            font-family: "Source Serif Pro",Sans-serif;
            font-size: 20px;
            font-weight: 200;
            color: #7a7a7a;
            white-space: nowrap;
            padding: 7px 27px;
            transition: .4s;

            &:focus, &:hover {
                color: #F58FB2;
            }
        }
    }

    &-navaction {
        width: 14.757%;

        &-item {
            margin-left: auto;
            display: inline-block;
            line-height: 1;
            font-family: "Montserrat",Sans-serif;
            fill: #FFF;
            color: #FFF;
            background-color: #EAAA68;
            border-radius: 25px 25px 25px 25px;
            box-shadow: 0 10px 30px -9px rgba(0, 0, 0, 0.16);
            padding: 12px 30px 12px 30px;
            font-weight: 500;
            font-size: 13px;

            &:hover {
                color: #FFF;
                background-color: #EAAA68;
            }
        }
    }
}