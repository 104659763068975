@import './variables.scss';
@import './metronic-theme.scss';

body {
    /*Cutom Only*/
    .kt-splash-screen {
      background-color: #fff;
    }

    /*Cutom Only*/
}